<template>
<ion-page>
    <syaberu-menu/>
    <ion-content :fullscreen="true">
   <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn"
    leave-active-class="animated bounceOutRight"
    v-on:after-leave="q2 = !q2"
   >
        <div v-if="q1">
            <h3 class="ion-margin">いつ、サプライズしましょうか？</h3>
             <ion-card>
               <ion-card-header>
                 <ion-card-title>登園時</ion-card-title>
               </ion-card-header>
               <ion-card-content>
                 <ion-button shape="round" @click="selectTiming('clockIn')">選択</ion-button>
               </ion-card-content>
             </ion-card>
             <ion-card>
               <ion-card-header>
                 <ion-card-title>降園時</ion-card-title>
               </ion-card-header>
               <ion-card-content>
                 <ion-button shape="round" @click="selectTiming('clockOut')">選択</ion-button>
               </ion-card-content>
              </ion-card>
        </div>
        </transition>
        <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn"
    leave-active-class="animated zoomOut"
  >
        <div v-if="q2">   
          <h3 class="ion-margin">発話させるメッセージを作って、送信を押してください！</h3>
          <ion-card>       
        <ion-card-content>
       <ion-textarea class="message" cols="30" rows="3" placeholder="メッセージを記入してください" v-model="message"></ion-textarea>
       <ion-button shape="round" @click="register()">送信</ion-button>
       <ion-button shape="round" color="warning" @click="backToQ1()">やりなおす</ion-button>
       </ion-card-content>
          </ion-card>
        </div>
          </transition>
    </ion-content>
</ion-page>
</template>

<script>
import { 
IonPage,  
IonContent,
IonCard,
IonCardHeader,
IonCardTitle,
IonCardContent,
IonButton,
IonTextarea,
alertController} from '@ionic/vue';
import {  megaphoneOutline} from 'ionicons/icons';
import  SyaberuMenu from './SyaberuMenu.vue'; 
export default  {
  name: 'Hello',
  data(){
    return {
      users: this.$store.state.users, //from vuex
      selectedUser : null,
      selectedTiming : "clockIn",
      message : null,
      q1: true,
      q2: false,
    }
  },
  setup() {
    return {
      megaphoneOutline
    }
  },
  components: { 
  IonPage,
  IonContent, 
  IonTextarea,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  SyaberuMenu },
  mounted(){
  },
  methods : {
    selectTiming(timing){
        this.selectedTiming = timing;
        this.q1 = false;
    },
    async register(){
        if(this.selectedTiming && this.message){
            this.$root.startLoading();
            const json = {
                "tenantId" : this.$store.state.activeUser.tenantId,
                "userId" : this.$store.state.activeUser.id,
                "timing" : this.selectedTiming,
                "message" : this.message,
            };
            const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
            const RESERVATION = FUNCTIONS_HOST_NAME + "/reservation"   
            await this.axios.post(RESERVATION,json).catch(err => {
                alert(err);
            })
            await this.$root.endLoading();
            //finalize
            this.selectedUser = null;
            this.message = null;
            await this.presentAlert();   
        }else{
            //FIXME: Slackに通知
            console.log(`${this.selectedUser}:${this.message}:${this.selectedTiming}`)
            alert('メッセージは必ず入力してください。');
        }
    },
    backToQ1(){
      this.q1 = true;
      this.q2 = false;
      this.message = null;
      this.selectedUser = null;
      this.selectedTiming = "clockIn";
    },
    async presentAlert() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'ご利用ありがとうございます。',
          message: 'メッセージをお預かりしました！わくわくしてお待ちください！！',
          buttons: ['OK'],
        });
      await alert.present();
      this.backToQ1();
    },
  }
}
</script>
<style scoped>
 .message{
     padding: 10px;
     font-size: 16pt;
 }
</style>